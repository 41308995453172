.img_top
  display flex
  position relative
  margin-bottom 85px
  .wrap_in
    display flex
    height 100%
    align-self:flex-end
  @media screen and (max-width: 1170px)
    //margin 0 -20px 40px
    margin 0 0 40px
  @media screen and (max-width: 736px)
    margin-bottom 30px
  &.first
    height 400px
    @media screen and (max-width: 840px)
      height 260px
    .img_cont
      margin-top auto
      margin-bottom 80px
      position relative
      @media screen and (max-width: 840px)
        margin-bottom 40px
        width 100%

.over_white
  &:before
    content " "
    background-color rgba(255,255,255, 0.7)
    absolute-all(0)

.img_cont-row
  display flex
  margin 0 -10px

.img_cont-item
  flex 1 1 50%
  padding 0 10px
  p
    font-size rem(24)
    line-height 1.3
    font-family fontLight
    @media screen and (max-width: 568px)
      display none

