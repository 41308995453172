.edge
  padding-bottom 1px
  margin-bottom 60px
  display flex
  align-items center
  flex-wrap wrap
  @media screen and (max-width: 1170px)
    margin-bottom 30px
  li
    flex 1 1 33.3%
    padding-right 15px
    margin-bottom 20px
    @media screen and (max-width: 840px)
      flex 1 1 50%
      display flex
      align-items center
    @media screen and (max-width: 568px)
      flex 1 1 100%
      padding-right 0

  img
    display inline-block
    vertical-align middle
  span
    display inline-block
    vertical-align middle
    font-size rem(24)
    margin-left 20px

