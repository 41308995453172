.video, .slider_photo
  padding-top 90px
  margin-bottom 70px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
    padding-top 50px
  @media screen and (max-width: 840px)
    margin-bottom 10px
    padding-top 30px


.video_sl
  font-size 0
  margin-left -2.5%
  text-align center
  @media screen and (max-width: 481px)
    margin-left 0
  > div
    display inline-block
    vertical-align top
    width 30.8%
    margin-left 2.5%
    margin-bottom 20px
    @media screen and (max-width: 840px)
      width 46.8%
    @media screen and (max-width: 481px)
      width auto
      margin-left 0
      margin-bottom 0
      display block
      vertical-align inherit

  .btn_video
    display block
    absolute($bottom: 11px, $left: 11px)
    @media screen and (max-width: 840px)
      size(40px)
  a
    position relative
    display block
    &:hover
      .btn_video
        background-image url("../img/general/btn_video_hover.svg")
  img
    display block
    max-width 100%
    height auto
    @media screen and (max-width: 481px)
      width 100%

  p
    padding 20px 0
    text-align center
    font-size rem(24)
    font-family fontLight
    line-height 1
    @media screen and (max-width: 840px)
      font-size rem(20)
      padding 8px 0

.video_sl
  @media screen and (max-width: 481px)
    margin-bottom 60px
  &.arr_slider
    .slick-arrow
      @media screen and (max-width: 481px)
        bottom -25px
      &.slick-next
          right 0
        &.slick-disabled
          opacity 0.3
      &.slick-prev
        left 0
        &.slick-disabled
          opacity 0.3

.slider_info.info
  margin 90px -10px 0
  .slick-slide
    padding 0 10px
    a
      display block
      margin-bottom 20px
      &:last-child
        margin-bottom 0
      img
        width 100%
        display block

  img
    max-width 100%
    height auto
    display block
    margin auto
  &.arr_slider
    .slick-arrow
      top 0
      transform translateY(-36px)
      position absolute
      &.slick-next
        right 5px
        @media screen and (max-width: 840px)
          right 10px
      &.slick-prev
        left 5px
        @media screen and (max-width: 840px)
          left 10px
  @media screen and (max-width: 1170px)
    margin-top 50px
  @media screen and (max-width: 840px)
    margin-top 30px
