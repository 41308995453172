.header
  padding 28px 0 26px
.header_main
  max-width 1170px
  margin 0 auto
  display flex
  @media screen and (max-width: 769px)
      max-width 620px
.header_main--logo
  display inline-block
  max-width 208px
  @media screen and (max-width: 960px)
    max-width 167px
  @media screen and (max-width: 480px)
    max-width 130px

  img
    max-width 100%
    height auto
.header_main--cont
  flex 1
  display flex
  flex-direction column
.language
  display block
  font-size 0
  text-align right
  margin-bottom 55px
  @media screen and (max-width: 960px)
    margin-bottom 40px
  @media screen and (max-width: 840px)
    z-index 55
    position relative
  @media screen and (max-width: 840px)
    margin-bottom 30px
  li
    margin-left 30px
    display inline-block
    &:first-child
      margin-left 0
    &.active , &:hover
      a
        color mainColor
    a
      color colorLink
      font-size rem(16)
      text-transform uppercase

// nav

.cd-nav-trigger {
  display: inline-block;
  position relative
  height: 100%;
  width: 40px;
}

.cd-nav-trigger b{
  display: inline-block;
  position: relative;
  right: 49px;
  font-weight: normal;
  font-size 20px;
  text-transform uppercase;
  top: 2px;
}

.cd-nav-trigger span {
  position: absolute;
  height: 2px;
  width: 40px;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -20px;
  transition: background-color 0.3s 0.3s;
  background-color: #000;
}
.cd-nav-trigger span::before, .cd-nav-trigger span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  -moz-transition: -moz-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
}
.cd-nav-trigger span::before {
  transform: translateY(-8px);
}
.cd-nav-trigger span::after {
  transform: translateY(8px);
}
.nav-is-visible .cd-nav-trigger span {
  background-color: rgba(255, 255, 255, 0);
}
.nav-is-visible .cd-nav-trigger span::before {
  transform: translateY(0) rotate(-45deg);
}
.nav-is-visible .cd-nav-trigger span::after {
  transform: translateY(0) rotate(45deg);
}

@media only screen and (min-width: 841px) {
  .cd-nav-trigger {
    display: none;
  }
}

.menu_wr
  position relative
  display flex
  justify-content flex-end
  &.right_nav_main
    &.nav-is-visible
      position fixed
      right 20px
      top 78px
  @media screen and (max-width: 840px)
    z-index 55
// nav

.menu
  display flex
  flex 1 1 100%
  justify-content flex-end
  &.nav-is-visible
    @media screen and (max-width: 840px)
      transform translateX(0px)
  @media screen and (max-width: 840px)
    //display none
    width 374px
    position fixed
    top 0
    background-color #fff
    right 0
    bottom 0
    height 100%
    min-height 100vh
    z-index 50
    display block
    padding 175px 40px 0
    box-shadow 0 10px 10px rgba(0,0,0,.25)
    overflow-y auto
    transition transform 0.3s
    transform translateX(374px)
    @media screen and (max-width: 384px)
      width 310px

  >li
    position relative
    margin-left 6.5%
    @media screen and (max-width: 960px)
      margin-left 4.5%
    @media screen and (max-width: 840px)
      margin-left 0
      margin-bottom 27px
    &:first-child
      margin-left 0
    &.active, &:hover
      >a
        &:after
          content " "
          absolute($bottom: -20px, $left: 0, $right: 0)
          background url("../img/general/menu_active.png") 0 0 repeat-x
          size(100%, 8px)
          @media screen and (max-width: 840px)
            absolute($bottom: -15px, $left: 0, $right: 0)
    &:hover
      .menu_drop
        display block

    &:hover, &.active:hover
      >a
        &:after
          background-position 0 -9px
    >a
      color mainColor
      font-size rem(20)
      text-transform uppercase
      position relative

.menu_drop
  position absolute
  background-color #fff
  padding 30px 20px 20px 40px
  top: 100%
  left -40px
  z-index 99
  min-width 300px
  display none

  @media screen and (max-width: 960px)
    top: 43px
  @media screen and (max-width: 840px)
    background none
    position relative
    padding 0
    display block
    top auto
    left auto
    min-width inherit
    margin-top 27px


.menu_in
  li
    margin-bottom 18px
    &.active, &:hover
      > a > span
        &:after
          content " "
          absolute($bottom: -10px, $left: 0, $right: 0)
          background url("../img/general/menu_active.png") 0 0 repeat-x
          size(100%, 8px)
    &:hover, &.active:hover
      > a > span
        &:after
          background-position 0 -9px
    a
      font-size rem(18)
      display flex
      align-items center
    span
        display inline-block
        position relative
    img + span
      margin-left 20px


.headroom
  position fixed
  z-index 50
  right 0
  left 0
  top 0
  will-change transform
  transition transform .25s ease-in-out
  + .content
      margin-top 176px
      +r(841)
        margin-top 152px
      +r(480)
        margin-top 130px

//.headroom--pinned
//  display block
//
//.headroom--unpinned
//  display none

.headroom--pinned
    transform translateY(0)

.headroom--unpinned
    transform translateY(-100%)
    +r(841)
      transform translateY(0)


.headroom--not-top
  &.header
      box-shadow 0 0 3px rgba(0,0,0, 0.5)
      padding 10px 0
      margin-top -3px
      background-color #fff
      transition transform .25s ease-in-out
    .menu_wr.right_nav_main.nav-is-visible
      top 40px
      +r(480)
        top 45px
    .header_main--logo
      max-width 110px
    .language
      margin-bottom 10px
      +r(480)
        margin-bottom 15px
    .menu >li.active >a:after,
    .menu >li:hover >a:after
      bottom -12px
