.resept
  .like_m
    margin-bottom 0
  .tab_row--table
    margin-bottom 20px
    @media screen and (max-width: 840px)
      margin-bottom 0
    tr
      &:first-child
        td
         padding-top 0
      &:last-child
        border none
      td
        text-align left
        padding 30px 9px
        @media screen and (max-width: 520px)
          word-break break-all
          padding 20px 9px
  .tab_row--table tr:first-child td
    white-space inherit

.resept--desc
  margin-right 20%
  @media screen and (max-width: 840px)
    margin-right 0
    padding-bottom 20px
  p
    font-size rem(24)
    line-height 1.2
    font-family fontLight
    margin-bottom 20px


.link_res
  display flex
  font-size rem(20)
  font-family fontLight
  span
    display inline-block
    margin-left 37px
    @media screen and (max-width: 520px)
      margin-left 20px
