.slider_main--wr
  overflow hidden
  max-height 728px
  height 100%
.slider_main
  &.arr_slider
    .slick-arrow
      top 50%
      transform translateY(-50%)
      &.slick-next
        right 54px
        @media screen and (max-width: 480px)
          right 17px
        &.slick-disabled
          opacity 0.3
      &.slick-prev
        left 54px
        @media screen and (max-width: 480px)
          left 17px
        &.slick-disabled
          opacity 0.3
.char_main--sl
  &.arr_slider
    .slick-arrow
      top 50%
      transform translateY(-50%)
      @media screen and (max-width: 600px)
        top 170px
        transform translateY(0)
      @media screen and (max-width: 375px)
        top 130px

//     dots
  .slick-dots
    padding-top 60px
    display flex
    flex-wrap wrap
    justify-content center
    @media screen and (max-width: 840px)
      padding-top 30px
    li
      &.slick-active
        background-color colorBgBlue
        border-color colorBgBlue
      size(16px)
      border-radius 50%
      border solid 2px #000
      margin 0 3px 6px
      button
        opacity 0