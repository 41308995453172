.quality_main, .resept--desc
  .h2_page
    text-align left
    @media screen and (max-width: 840px)
      text-align center
    @media screen and (max-width: 840px)
      margin-bottom 30px
    @media screen and (max-width: 736px)
        margin-bottom 15px
    br
      @media screen and (max-width: 840px)
        display none
.quality
  p
    font-size rem(24)
    line-height 1.2
    font-family fontLight

.quality__list
  display flex
  justify-content space-between
  margin-bottom 70px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 840px)
    margin-bottom 30px
    @media screen and (max-width: 736px)
      margin-bottom 20px
  li
    padding-right 30px
    &:last-child
      padding-right 30px
  img
    max-width 100%
    height auto
    display block