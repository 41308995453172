.servises
  display flex
  flex-wrap wrap
  margin-bottom 80px
  .h2_page
    margin-bottom 45px
    text-align left
    @media screen and (max-width: 568px)
      margin-bottom 30px
  p
    font-size rem(24)
    line-height 1.3
    font-family fontLight

  @media screen and (max-width: 1170px)
    margin 0 -20px 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px

.serv_item
  flex 1 1 50%
  padding 80px 70px 80px 50px
  &.picturefill-background
    @media screen and (max-width: 568px)
      height 160px
  &:last-child
    @media screen and (max-width: 568px)
      order 3
  &:nth-last-child(2)
    @media screen and (max-width: 568px)
      order 4
      padding-bottom 0

  @media screen and (max-width: 840px)
    padding 40px 40px 40px 11%
  @media screen and (max-width: 568px)
    flex 1 1 100%
    padding 40px 15px 40px 15px