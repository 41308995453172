.catalog_desc
  margin 0 auto 100px
  display flex
  justify-content space-between
  align-items center
  text-align center
  &.second
    text-align left
    width 100%
    .info_btn
      text-align center
      @media screen and (max-width: 840px)
        width auto
    p
      @media screen and (max-width: 384px)
        font-size rem(24)

  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px
  @media screen and (max-width: 700px)
    flex-wrap wrap
  p
    font-size rem(24)
    padding-right 20px
    flex 1
    @media screen and (max-width: 700px)
      flex 1 1 100%
      margin-bottom 20px
      padding-right 0
    @media screen and (max-width: 384px)
      font-size rem(16)
      padding-right 0
      text-align left
    b
      font-weight fontBold