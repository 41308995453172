.map__row
  align-items center
  .br__right
    padding 30px 30px 30px 0
    height 565px
    @media screen and (max-width: 840px)
      height 370px
    @media screen and (max-width: 568px)
      padding-left 10px
    .gm-style
      img[src="static/img/general/point.svg"], .gmnoprint
        width 42px!important
        height 50px!important
  .half__item
    &:first-child
      @media screen and (max-width: 840px)
        order 2
    &:last-child
      justify-content flex-end
      display flex
    @media screen and (max-width: 840px)
      order 1
    .h3_
      margin-bottom 60px
      @media screen and (max-width: 1170px)
        margin-bottom 40px
      @media screen and (max-width: 840px)
        font-size rem(48)
        margin-bottom 40px
      @media screen and (max-width: 568px)
        font-size rem(36)
        margin-bottom 30px

  .wrap_mob
    max-width 470px
    width 100%
    @media screen and (max-width: 1025px)
      padding-left 30px
    @media screen and (max-width: 840px)
      max-width 620px
      padding-left 0

//  map
#map
  position relative
  height 100%
  #canvas-map
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 1
// map


.cotnt_list
  @media screen and (max-width: 840px)
    display flex
    flex-wrap wrap
    padding-bottom 50px
  @media screen and (max-width: 568px)
    padding-bottom 35px
  li
    margin-bottom 35px
    @media screen and (max-width: 840px)
      flex 1 1 50%
      padding-right 40px
    @media screen and (max-width: 568px)
      flex 1 1 100%
    &:last-child
      margin-bottom 0
.icon_item
  display flex
  font-size rem(20)
  strong
    display block
    margin-bottom 1px
    font-weight 500
  span
    font-family fontLight
.icon_item-txt
  margin-left 28px




