.tab_row
  display flex
  margin-bottom 90px
  @media screen and (max-width: 840px)
    flex-wrap wrap
  p
    margin-bottom 30px
  >div:first-child
    width 400px
    padding-right 50px
    font-size rem(24)
    line-height 1.3
    @media screen and (max-width: 840px)
      width 100%
      max-width 620px
      margin  0 auto 30px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px
  .like_m
    margin-bottom 0
    flex 1
    @media screen and (max-width: 384px)
      border-left none
      padding-left 20px
      margin-left -20px
.tab_row--table
  width 100%
  margin-bottom 40px
  tr
    border-bottom 2px solid #000
    &:first-child
      td
        font-family fontBold
        height 60px
        white-space nowrap
    td
      font-size rem(24)
      width 25%
      padding 10px 10px
      text-align center
      vertical-align middle
      height 56px

      @media screen and (max-width: 568px)
        font-size rem(20)
        padding 0
      @media screen and (max-width: 384px)
        font-size rem(16)