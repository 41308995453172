.map_block
  margin 90px 0
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 40px
  @media screen and (max-width: 1020px)
    display none
.map_block--main
  padding 160px 0 90px

.map_main
  display flex
  max-width 770px
  margin auto
  justify-content center
  position relative
  background-image url("../img/content/map/map_start.png")
  background-size 100%
  background-position 0 0
  background-repeat no-repeat
  &.img_h
    background none

.manager21, .manager22, .manager23
  &.active
    background-image url("../img/content/map/manager2.png")
.kiev
  &.active
    background-image url("../img/content/map/kiev.png")
.odessa
  &.active
    background-image url("../img/content/map/odessa.png")
.lviv
  &.active
    background-image url("../img/content/map/lviv.png")
.manager1
  &.active
    background-image url("../img/content/map/manager1.png")
.map-hover
  absolute-all(0)
  //z-index 1

.bs-region
  absolute-all(0)
  &.opacity
    .top_map, .top_map_man p, .men1:before, .men2:before
      opacity 0.2

.manager23.active + .manager21.opacity, .manager22.active + .manager23.opacity + .manager21.opacity
  .top_map, .top_map_man p, .men1:before, .men2:before
    opacity 1

.map_empty
  position relative
  z-index 2
.top_map
  position absolute
  padding-bottom 15px
  border-bottom 2px solid #000

  .icon_item-txt
    margin-left 20px
    strong
      font-size rem(36)
      line-height 1.1

  &.tm1, &.tm2, &.tm3
    &:before
      content ""
      background-color lineColor
      width 2px
  &.tm1
    width 258px
    absolute($top: -159px, $left: 101px)
    &:before
      height 165px
      absolute($bottom: -165px, $right: 0px)
  &.tm2
    width 286px
    absolute($top: -62px, $left: -203px)
    @media screen and (max-width: 1180px)
      width 201px
      absolute($top: -62px, $left: -118px)
    &:before
      height 147px
      absolute($bottom: -147px, $right: 0px)
  &.tm3
    width 270px
    absolute($top: -161px, $right: 100px)
    &:before
      height 467px
      absolute($bottom: -467px, $left: 0px)
    &:after
      content ""
      width 28px
      height 2px
      background-color lineColor
      absolute($bottom: -467px, $left: -28px)

.top_map_man
  position absolute
  bottom 0
  width 350px
  &.men1
    left -174px
    bottom -55px
    z-index 10
    @media screen and (max-width: 1180px)
      left -95px
    &:before
      content ""
      background-color lineColor
      size(433px, 2px)
      display block
      @media screen and (max-width: 1180px)
        width 354px

    .top_map
      border-left 2px solid lineColor
      img
        background-color #fff
        margin-left -27px
    p
      padding-left 40px
      padding-right 5px

  &.men2
    right -175px
    bottom -53px
    z-index 10
    @media screen and (max-width: 1180px)
      right -92px
    &:before
      content ""
      background-color lineColor
      size(433px, 2px)
      display block
      absolute($top: 0, $left: -84px)
      @media screen and (max-width: 1180px)
        width 351px
        absolute($top: 0, $left: -1px)
    .top_map
      border-right 2px solid lineColor
      text-align right
      .icon_item-txt
        margin-left 0
        margin-right 20px
        @media screen and (max-width: 1180px)
          margin-right 15px
      img
        background-color #fff
        margin-right -27px

    .icon_item
      justify-content flex-end
    p
      padding-right 40px
      padding-left 5px
      text-align right

  .top_map
    position relative
    display block
    padding-top 90px
  p
    font-size rem(18)
    font-family fontLight
    line-height 1.3
    padding-top 20px