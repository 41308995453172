.part__bl--head
  display flex
  align-items center
  margin-bottom 80px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px
  @media screen and (max-width: 455px)
    flex-wrap wrap

  .h1_
    width 33.3%
    padding-right 20px
    @media screen and (max-width: 455px)
      margin-bottom 20px
      flex 1 1 100%
  p
    flex 1
    font-family fontLight
    font-size rem(24)
    line-height 1.3
    @media screen and (max-width: 455px)
      flex 1 1 100%
.part__sl_img
  img
    display block
    transition all 0.1s
    border 10px solid #ECEFF1
    max-width 100%
    height auto
  img.hov
    display none
  &:hover
    img
      display none
    img.hov
      display block
      border-color colorRed

.slick-current
  .part__sl_img
      img
        display none
      img.hov
        display block
        border-color colorRed

.part__sl
  margin 0 -15px 140px
  @media screen and (max-width: 1170px)
    margin-bottom 80px
  @media screen and (max-width: 655px)
    margin-bottom 80px
  .slick-slide
    padding 0 15px

.part__sl
  &.arr_slider
    .slick-arrow
      bottom -38px
      @media screen and (max-width: 700px)
        bottom -33px
      &.slick-next
        right 15px
        @media screen and (max-width: 769px)
          right 25px
        @media screen and (max-width: 414px)
          right 15px
        &.slick-disabled
          opacity 0.3
      &.slick-prev
        left 15px
        &.slick-disabled
          opacity 0.3

.part__wr_main
  @media screen and (max-width: 840px)
    max-width 605px
    margin auto