.btn_empty
  width 270px
  height 70px
  border 2px solid #000000
  line-height 66px
  text-align center
  font-size rem(24)
  display inline-block
  transition all 0.1s
  &:hover
    background-color #000
    color #fff

button.btn_empty
  background-color #fff
  cursor pointer
  &:hover
    background-color #000