.c_stock
  padding-top 95px
  @media screen and (max-width: 1170px)
    padding-top 50px
  @media screen and (max-width: 481px)
    padding-top 40px
  .icon_item
    @media screen and (max-width: 481px)
      font-size 18px

.sep
  height 2px
  background-color #ECEFF1
  margin-bottom 90px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 40px

.title_c
  @media screen and (max-width: 840px)
    margin-bottom 50px
  @media screen and (max-width: 481px)
    font-size rem(36)
    margin-bottom 40px
  img
    @media screen and (max-width: 481px)
      display none
  span
    @media screen and (max-width: 481px)
      margin-left 0
.c_list
  li
    padding 30px 0
    border-bottom 2px solid #000
    @media screen and (max-width: 481px)
      padding 20px 0
    &:first-child
      padding-top 0
      @media screen and (max-width: 481px)
        padding-top 0
    &:last-child
      border-bottom 0
      padding-bottom 0
      @media screen and (max-width: 481px)
        padding-bottom 0
    .icon_item-txt
      @media screen and (max-width: 481px)
        margin-left 24px


.personal_list
  margin-bottom 40px
  .title_c
    margin-bottom 0
  &.cor
    margin-top -20px
  @media screen and (max-width: 1170px)
    margin-bottom 10px
  @media screen and (max-width: 840px)
    margin-bottom 1px
  li
    border-bottom 2px solid #000
    padding 43px 0
    @media screen and (max-width: 600px)
      padding 20px 0
    &:first-child
      padding-top 0
    &:last-child
      border-bottom none

.personal_title
  font-size rem(36)
  font-weight normal
  @media screen and (max-width: 840px)
    margin-bottom 20px
.personal_
  display flex
  @media screen and (max-width: 840px)
    flex-wrap wrap

.personal_item
  flex 1 1 33.3%
  .icon_item-txt
    @media screen and (max-width: 481px)
      margin-left 20px
      font-size rem(18)
  &:first-child
    @media screen and (max-width: 840px)
      flex 1 1 100%
  &:last-child
    @media screen and (max-width: 600px)
      margin-top 24px

  @media screen and (max-width: 840px)
    flex 1 1 50%
  @media screen and (max-width: 600px)
    flex 1 1 100%

  .icon_item
    margin-bottom 36px
    @media screen and (max-width: 600px)
      margin-bottom 24px
    &:last-child
      margin-bottom 0