.inv_main
  padding 50px 0 100px
  display flex
  justify-content space-between
  @media screen and (max-width: 1170px)
    padding-bottom 40px
  @media screen and (max-width: 980px)
    flex-wrap wrap
  @media screen and (max-width: 736px)
    padding-bottom 30px
  @media screen and (max-width: 568px)
    padding-top 40px

.inv_main--video
  position relative
  border 30px solid colorRed
  border-right 0
  padding 30px 0px 30px 30px
  img
    max-width 100%
    height auto
    @media screen and (max-width: 980px)
      width 100%
  @media screen and (max-width: 980px)
    width 100%
  .btn_video
    absolute($bottom: 60px, $left: 60px)
    z-index 2
  &:after, &:before
    content " "
    height 30px
    position absolute
    background-color colorRed
    left 100%
    right -20000%
    display block
  &:after
    top -30px
  &:before
    bottom -30px

.inv_main--left
  width 400px
  padding-left 0px
  padding-right 30px
  @media screen and (max-width: 980px)
    max-width 620px
    margin auto
    width auto
    margin-bottom 50px
    display flex
    padding 0
  @media screen and (max-width: 568px)
    flex-wrap wrap
  .h1_
    margin-bottom 50px
    @media screen and (max-width: 568px)
      margin-bottom 30px
    br
      @media screen and (max-width: 980px)
        display none
    @media screen and (max-width: 980px)
      flex 1 1 50%
    @media screen and (max-width: 568px)
      flex 1 1 100%
  p
    font-size rem(24)
    line-height 1.3
    margin-bottom 50px
    font-family  fontLight
    @media screen and (max-width: 980px)
      margin-bottom 30px

.inv_desc
  @media screen and (max-width: 980px)
    flex 1 1 50%
  @media screen and (max-width: 568px)
    flex 1 1 100%

.btn_video
  background-image url("../img/general/btn_video.svg")
  @extends .corBG
  size(80px)
  &:hover
    background-image url("../img/general/btn_video_hover.svg")

.inv_main_v
  position relative
  iframe
    absolute-all(0)
    width 100%
    height 100%

.inv_main_vpic
  position relative
  transition all 0.2s
  &.isHide
    opacity 0
    z-index -10

