.text
  font-size rem(24)
  line-height 1.5
  margin-bottom 70px
  font-family fontLight
  columns 540px 2
  p
    @media screen and (max-width: 603px)
      margin-bottom 20px

  @media screen and (max-width: 1170px)
    margin-bottom 40px
  @media screen and (max-width: 1145px)
    columns 300px 2
  @media screen and (max-width: 840px)
    columns 270px 2
    line-height 1.3
  @media screen and (max-width: 736px)
    margin-bottom 20px