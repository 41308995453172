.contact_form label.error
  position absolute
  right 0
  left 0
  bottom 100%
  padding 5px 10px
  font-size rem(16)
  line-height 1.2
  color #fff
  background colorRed
  font-weight normal
  margin 0
  text-align left


.contact_form label.error:after
  content ''
  position absolute
  left 94%
  bottom -5px
  width 0
  height 0
  border-style solid
  border-width 5px 5px 0 5px
  border-color colorRed transparent transparent transparent

.contact_form label.valid
  display none!important

.contact_form input
  height 56px
.contact_form textarea
  height 120px
.contact_form input,
.contact_form textarea
  width 100%
  border-radius 28px
  background-color #fff
  border solid 2px #000
  font-family fontMain
  font-size rem(24)
  padding 10px 27px
  +placeholder()
    color #000

.form
  padding 80px 0 100px
  background-color colorBgBlue
  @media screen and (max-width: 1170px)
    padding 50px 0 50px
  @media screen and (max-width: 736px)
    padding 30px 0 30px

.form_main
  display flex
  margin 0 -10px
  @media screen and (max-width: 568px)
    flex-wrap wrap
  .h1_
    @media screen and (max-width: 840px)
      font-size rem(48)
      padding-right 10px
    @media screen and (max-width: 568px)
      font-size rem(36)
      margin-bottom 30px

.form__main--item
  flex 1 1 50%
  padding 0 10px
  &:first-child
    p
      font-size rem(24)
      line-height 1.3
      margin-top 60px
      font-family fontLight
      max-width 480px
      @media screen and (max-width: 1170px)
        margin-top 40px
      @media screen and (max-width: 840px)
        margin-top 20px
        margin-bottom 35px
  @media screen and (max-width: 568px)
    flex 1 1 100%

.form-group
  position relative
  margin-bottom 30px
  @media screen and (max-width: 840px)
    margin-bottom 20px
  p
    font-size rem(18)
    @media screen and (max-width: 1030px)
      flex 1 1 100%
      margin-bottom 15px


  &:last-child
    margin-bottom 0

.form_btn--row
  display flex
  justify-content space-between
  align-items center
  button.btn_empty
    @media screen and (max-width: 414px)
      width 100%
  @media screen and (max-width: 1030px)
    flex-wrap wrap
  @media screen and (max-width: 840px)
    margin-top -10px