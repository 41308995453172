@font-face {
  font-family: 'yanone_kaffeesatzregular';
  src: url('../fonts/YanoneKaffeesatz-Regular/yanonekaffeesatz-regular-webfont.eot');
  src: url('../fonts/YanoneKaffeesatz-Regular/yanonekaffeesatz-regular-webfont.eot?#iefix') format('embedded-opentype'),
          url('../fonts/YanoneKaffeesatz-Regular/yanonekaffeesatz-regular-webfont.woff2') format('woff2'),
          url('../fonts/YanoneKaffeesatz-Regular/yanonekaffeesatz-regular-webfont.woff') format('woff'),
          url('../fonts/YanoneKaffeesatz-Regular/yanonekaffeesatz-regular-webfont.svg#yanone_kaffeesatzregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'yanone_kaffeesatzlight';
  src: url('../fonts/YanoneKaffeesatz-Light/yanonekaffeesatz-light-webfont.eot');
  src: url('../fonts/YanoneKaffeesatz-Light/yanonekaffeesatz-light-webfont.eot?#iefix') format('embedded-opentype'),
          url('../fonts/YanoneKaffeesatz-Light/yanonekaffeesatz-light-webfont.woff2') format('woff2'),
          url('../fonts/YanoneKaffeesatz-Light/yanonekaffeesatz-light-webfont.woff') format('woff'),
          url('../fonts/YanoneKaffeesatz-Light/yanonekaffeesatz-light-webfont.svg#yanone_kaffeesatzlight') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'yanone_kaffeesatzbold';
  src: url('../fonts/YanoneKaffeesatz-Bold/yanonekaffeesatz-bold-webfont.eot');
  src: url('../fonts/YanoneKaffeesatz-Bold/yanonekaffeesatz-bold-webfont.eot?#iefix') format('embedded-opentype'),
          url('../fonts/YanoneKaffeesatz-Bold/yanonekaffeesatz-bold-webfont.woff2') format('woff2'),
          url('../fonts/YanoneKaffeesatz-Bold/yanonekaffeesatz-bold-webfont.woff') format('woff'),
          url('../fonts/YanoneKaffeesatz-Bold/yanonekaffeesatz-bold-webfont.svg#yanone_kaffeesatzbold') format('svg');
  font-weight: normal;
  font-style: normal;

}