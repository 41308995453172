.ingradient
  display flex
  flex-wrap wrap
  margin-left -1%
  margin-bottom 90px
  @media screen and (max-width: 1170px)
    margin-bottom 40px
  @media screen and (max-width: 736px)
    margin-bottom 20px
  &.plant
    margin-bottom 20px

.ingradient_item_t
  flex 0 1 49%
  margin-left 1%
  //padding 30px
  display flex
  margin-bottom 10px
  position relative
  &:first-child
    .ingradient_item
      @media screen and (max-width: 840px)
        height auto
        padding 30px 0
  @media screen and (max-width: 375px)
    flex-direction column
  @media screen and (max-width: 375px)
    padding 0
  .h2_
    @media screen and (max-width: 568px)
      margin 25px 0
  @media screen and (max-width: 840px)
    flex 0 1 99%

.ingradient_item
  height 340px
  width 100%
  display flex
  justify-content center
  flex-direction column
  @media screen and (max-width: 375px)
    flex-wrap wrap
    height 200px

.ingradient_title
  justify-content center
  align-items center
  text-align center

.icon__gr
  position absolute
  top 30px
  left 30px
  bottom 30px
  width 240px
  height 280px
  padding 25px 30px
  background-color #fff
  display flex
  flex-direction column
  &:hover
    .link_:after
      background-image url("../img/general/hov_link_next.svg")
      width 70px
      height 12px

  @media screen and (max-width: 375px)
    position relative
    top auto
    left auto
    bottom auto
    height auto
    width auto
    padding 20px 20px 15px 50px
  img
    display inline-block
    margin-bottom 25px
    @media screen and (max-width: 375px)
      display none
  .link_
    margin-top auto
    margin-bottom 25px
    &:after
      bottom -25px
      @media screen and (max-width: 375px)
        bottom -17px
.info_btn
  width 410px
  @media screen and (max-width: 840px)
    width auto
  @media screen and (max-width: 700px)
    flex 1 1 100%

