.arr_slider
  .slick-arrow
    size(33px, 12px)
    display block
    position absolute
    background-size 100%
    background-repeat no-repeat
    background-color transparent
    z-index 10
    overflow hidden
    text-indent -999px
    color transparent
    outline none
    cursor pointer
    border none
    &.slick-next
      background-image url("../img/general/arr_sl_next.svg")
      right 0
    &.slick-prev
      background-image url("../img/general/arr_sl_prev.svg")
      left 0


.h1_
  font-family fontSecond
  font-size rem(72)
  line-height 1.2
  text-transform uppercase
  position relative
  @media screen and (max-width: 1025px)
    font-size rem(60)
  @media screen and (max-width: 568px)
    font-size rem(48)
.h2_
  font-family fontSecond
  color white
  font-size rem(100)
  line-height 1.2
  text-transform uppercase
  br
    @media screen and (max-width: 840px)
      display none
  @media screen and (max-width: 1025px)
    font-size rem(72)
  @media screen and (max-width: 568px)
    font-size rem(36)

.h2_page
  font-family fontSecond
  line-height 1.2
  text-transform uppercase
  font-size rem(60)
  font-weight 700
  margin-bottom 80px
  text-align center
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 1025px)
    font-size rem(48)
  @media screen and (max-width: 736px)
    margin-bottom 30px
  @media screen and (max-width: 568px)
    font-size rem(36)

.h3_
  font-family fontSecond
  line-height 1.2
  text-transform uppercase
  font-size rem(48)
  font-weight 700
  @media screen and (max-width: 1025px)
    font-size rem(40)
  @media screen and (max-width: 568px)
    font-size rem(30)
.title_c
  font-family fontSecond
  line-height 1.2
  text-transform uppercase
  font-size rem(48)
  font-weight 700
  display flex
  align-items center
  span
    margin-left 50px

.h3_page
  font-family fontSecond
  line-height 1.2
  text-transform uppercase
  font-size rem(42)
  font-weight 700
  color white
  @media screen and (max-width: 568px)
    font-size rem(36)
.h4_
  line-height 1.2
  text-transform uppercase
  font-size rem(24)
  margin-bottom 30px
  font-weight normal
.link_
  font-size 24px
  position relative
  margin-bottom 17px
  display inline-block
  transition width 0.1s
  &:hover
    &:after
      background-image url("../img/general/hov_link_next.svg")
      size(70px, 12px)
  &:after
    content " "
    size(33px, 12px)
    background-image url("../img/general/arr_sl_next.svg")
    background-size 100%
    background-repeat no-repeat
    background-color transparent
    position absolute
    left 0
    bottom -17px

.rightStick, .leftStick
  position relative
  &:after, &:before
    content " "
    height 30px
    position absolute
    background-color colorRed
    display block
  &:after
    top -30px
  &:before
    bottom -30px

.rightStick
  &:after, &:before
    left 100%
    right -20000%

.leftStick
  &:after, &:before
    right  100%
    left -20000%

.type_inpt
  height 56px
  text-align center
  width 100%
  border-radius 28px
  background-color #fff
  border solid 2px #000
  font-size rem(30)

.calc__main--price
  font-size rem(72)
  font-family fontBold
  line-height 1

.wrap_mob
  @media screen and (max-width: 840px)
    max-width 620px
    margin 0 auto

.link_.second
  &:after
    left 62px
    bottom 0
  span
    display inline-block
    margin-left 28px
  img , span
    display inline-block
    vertical-align top

.half
  display flex
  margin 0 -10px 90px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px
  &.second
    .half__item
      &:first-child
        flex 1 1 40%
        @media screen and (max-width: 840px)
          width 100%
          flex 1 1 100%
      &:last-child
        flex 1 1 60%
        @media screen and (max-width: 840px)
          flex 1 1 100%
  @media screen and (max-width: 840px)
    flex-wrap wrap
  .half__item
    flex 1 1 50%
    padding 0 10px
    @media screen and (max-width: 840px)
      flex 1 1 100%

.getalo-page, .toppings-page, .ing_descr
  .link_:after
    display none


.sent_mess
  border 6px solid colorRed
  padding 3em 2em
  width 400px
  max-width 400px
  text-align center
  background #fff
  position fixed
  top 50%
  left 50%
  transform translate(-50%,-50%)
  z-index 100
  +r(480)
    left auto
    transform translate(0,-50%)
    margin 0 20px
    max-width inherit
    width calc(100% - 40px)
  p
    font-size rem(24)

.overly
    content ''
    display block
    width 100%
    height 100vh
    position fixed
    top 0
    left 0
    z-index 99
    background rgba(0, 0, 0, 0.7)


// simple close
.close_
  position absolute
  right 25px
  top 15px
  width 25px
  height 25px
  opacity 0.3
.close_:hover
  opacity 1
.close_:before, .close_:after
  position absolute
  left 15px
  content ' '
  height 33px
  width 2px
  background-color #333
.close_:before
  transform rotate(45deg)
.close_:after
  transform rotate(-45deg)


#g-recaptcha-30
  display flex
  justify-content flex-end
  > div
    +r(345)
      width 100%!important
  iframe
    +r(345)
      width 110%!important
      transform scale(0.9)
      position relative
      left -10px


