.calc
  padding 80px 0
  background-color colorBgBlue
  margin-bottom 80px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
    padding 50px 0 40px
  @media screen and (max-width: 736px)
    margin-bottom 30px
    padding 40px 0 20px
  .h3_
    margin-bottom 65px
    text-align center
    @media screen and (max-width: 665px)
      margin-bottom 30px
      padding 0 30px
.calc__main
  display flex
  flex-wrap wrap
  margin 0 -10px
  @media screen and (max-width: 840px)
    text-align center
    max-width: 632px;
    margin: 0 auto;
  strong
    display block
    color mainW
    font-size rem(24)
    line-height 1.3
    margin-bottom 20px
    font-weight normal
  p
    font-size rem(18)
    br
      @media screen and (max-width: 665px)
        display none
  .calc__main--item
    &:last-child
      strong, .calc__main--desc
        @media screen and (max-width: 957px)
          flex 1 1 50%
        @media screen and (max-width: 665px)
          flex 1 1 100%
.calc__main--desc
  p
    @media screen and (max-width: 957px)
      display none

.calc__main--item
  flex 0 1 33.3%
  padding 0 10px
  text-align center
  &:last-child
    @media screen and (max-width: 957px)
      display flex
      margin-top 40px
    @media screen and (max-width: 665px)
      flex-wrap wrap
      margin-bottom 0
      margin-top 20px
  @media screen and (max-width: 957px)
    flex 1 1 50%
  @media screen and (max-width: 665px)
    flex 1 1 100%
    margin-bottom 20px
    padding
  .type_inpt
    margin-bottom 25px
    @media screen and (max-width: 665px)
      margin-bottom 5px
  .calc__main--price
    position relative
    top -9px
    margin-bottom 10px