@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/extend.styl'
@import 'utils/mixins.styl'
@import 'libs.styl'

html
	font-size fontMain
	height 100%
	line-height 1.3

body
	height 100%
	background-color #fff
	font-family fontMain

h1,h2,h3,h4,h5,h6
	margin 0

ul,li
	list-style-type none
	margin 0
	padding 0

p
	margin 0

a
	color black
	text-decoration none

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle

textarea
	resize none

table
	border-collapse collapse
	border-spacing 0

section
	position relative
img
	outline none!important

:focus
	outline none

input,
select,
button
	outline none
	font-family fontMain

*, *:before, *:after
	box-sizing border-box

.wrapper
	display flex
	flex-direction column
	height 100%

.content
	flex 1 0 auto
	overflow hidden

.wrap
	max-width 1320px
	width 100%
	padding 0 20px
	margin auto
.wrap_in
	max-width 1170px
	width 100%
	margin auto
	@media screen and (max-width: 1170px)
		padding 0 20px

//Модули
@import "modules/modules.styl"

// СТРАНИЦЫ

// Главная страница
@import 'pages/index.styl'

// О нас
@import 'pages/about.styl'

// Джетало
@import 'pages/getalo.styl'

// суміши
@import 'pages/sumi.styl'

// Тара
@import 'pages/tare.styl'

// Обладнання
@import 'pages/equipment.styl'

// Iнформацiя
@import 'pages/information.styl'

// Контакти
@import 'pages/contacts.styl'