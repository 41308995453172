.char_main
  margin-bottom 90px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px

.char_main--row
  display flex
  max-width 960px
  margin auto
  align-items center
  @media screen and (max-width: 960px)
    padding 0 60px
  @media screen and (max-width: 600px)
    flex-wrap wrap
    padding 0
  .char_main--pic
    flex 1 1 50%
    @media screen and (max-width: 600px)
      flex 1 1 100%
    img
      max-width 100%
      display block
      margin auto
      height auto
    &:last-child
      padding-top 30px
      display flex
      justify-content flex-end

.char_table
  width 370px
  @media screen and (max-width: 840px)
    width 240px
  @media screen and (max-width: 600px)
    width 100%
  tr
    td
      font-size rem(24)
      padding 10px 0
      vertical-align middle
      @media screen and (max-width: 480px)
        padding 5px 0
      &:last-child
        width 90px
        text-align right
        font-family fontBold