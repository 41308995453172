.info_ic_gr
  display flex
  flex-wrap wrap
  justify-content center
  align-items center
  &.second
    align-items flex-start
.info_ic_item
  margin 0 5%
  @media screen and (max-width: 621px)
    margin-bottom 20px
  &:first-child
    margin-left 0
  img
    margin-bottom 35px
  p
    font-size rem(24)
