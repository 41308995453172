.ing_main
  margin-bottom 40px
  padding-bottom 1px
  &.second
    .ing_main-row
      align-items center
      @media screen and (max-width: 568px)
        margin-bottom 25px
    .link_
      position relative
      top 20px
      @media screen and (max-width: 960px)
        top 5px
  @media screen and (max-width: 1170px)
    margin-bottom 0
.ing_main-row
  display flex
  margin 0 -15px 50px
  @media screen and (max-width: 960px)
    margin 0 -10px 30px
  @media screen and (max-width: 600px)
    flex-wrap wrap
    margin-bottom 40px
  .link_
    font-size rem(36)
    margin-bottom 40px
    @media screen and (max-width: 960px)
      font-size rem(24)

.ing_main--item
  flex 1 1 50%
  padding 0 15px
  @media screen and (max-width: 960px)
    padding 0 10px
  @media screen and (max-width: 600px)
    flex 1 1 100%
  &:last-child
    display flex
    flex-direction column
    align-items flex-end

  &:first-child
    @media screen and (max-width: 600px)
      margin-bottom 30px
    img
      max-width 100%
      height auto
      display block
      width 100%

.ing_main--cont
  max-width 510px
  position relative
  width 100%
  padding-left 140px
  @media screen and (max-width: 960px)
    padding-left 60px
  @media screen and (max-width: 600px)
    max-width inherit
  img
    display block
    absolute($top: 0, $left: -140px)
    @media screen and (max-width: 960px)
      size(50px)
      absolute($top: 0, $left: -64px)
  p
    font-size rem(24)
    line-height 1.3
    font-family fontLight
    @media screen and (max-width: 960px)
      font-size rem(20)