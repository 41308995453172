footer
  flex 0 0 auto
  border-top 30px solid colorRed
  padding 90px 0 30px

  .wrap_in
    @media screen and (max-width: 769px)
      max-width 630px
  @media screen and (max-width: 1170px)
    padding-top 50px
  @media screen and (max-width: 736px)
    padding-top 50px
.sub_footer
  display flex
  padding-bottom 1px
  margin-bottom 35px
  @media screen and (max-width: 769px)
    justify-content space-between
  @media screen and (max-width: 425px)
    flex-wrap wrap
    justify-content center
    text-align center
    margin-bottom 10px
.sub_footer--left
  width 25%
  @media screen and (max-width: 769px)
    width 240px
  @media screen and (max-width: 425px)
    width 100%
    margin-bottom 20px
  p
    font-size rem(18)
    line-height 1.5
    text-transform uppercase
    margin-bottom 20px
.sub_footer--right
  flex 1
  display flex
  @media screen and (max-width: 769px)
    flex-direction column
    flex inherit
  @media screen and (max-width: 425px)
    width 100%
.sub_footer--item
  flex 1 1 33.3%
  @media screen and (max-width: 769px)
    flex inherit


.list_foot
  margin-bottom 25px
  padding-bottom 1px
  @media screen and (max-width: 840px)
    padding-right 20px
  @media screen and (max-width: 769px)
    display none
  @media screen and (max-width: 425px)
    flex-wrap wrap
  li
    margin-bottom 4px
    a
      font-family fontLight
      font-size rem(18)
      display inline-block
      border-bottom 1px solid transparent
      &:hover
          border-color #9e9e9e

.list_tel
  @media screen and (max-width: 840px)
    padding-right 20px
  @media screen and (max-width: 425px)
    padding-right 0
  li
    &:last-child
      a, span
        text-transform uppercase
    a, span
      display inline-block
      font-size rem(18)
      line-height 1.5

.footer_bottom
  display flex
  justify-content space-between
  @media screen and (max-width: 619px)
    flex-wrap wrap
  > p
      font-family fontLight
      font-size rem(18)
      @media screen and (max-width: 1121px)
        width 128px
      @media screen and (max-width: 619px)
        order 2
        flex 1 1 100%
        justify-content content
        margin-top 20px
        text-align center
  .quality__list
    margin-bottom 0
    li
      padding-right 14px
      &:last-child
        @media screen and (max-width: 769px)
          padding-right 15px

  > div
    flex 1
    display flex
    justify-content flex-end
    align-items center
    @media screen and (max-width: 1121px)
      flex-wrap wrap
    @media screen and (max-width: 619px)
      order 1
      flex 1 1 100%
      justify-content space-between
      > div
        @media screen and (max-width: 1121px)
          flex 1 1 87px
        @media screen and (max-width: 839px)
          flex 1 1 50%


.logo__
  font-family fontLight
  font-size rem(18)
  display inline-block
  a
    border-bottom 1px solid #9e9e9e
    display inline-block
    margin-left 5px
    &:hover
      border-color transparent
  @media screen and (max-width: 1121px)
    width 100%
    text-align right
    margin-top 20px
    display block
  @media screen and (max-width: 619px)
    text-align center
.social_list
  display flex
  margin 0 4%
  @media screen and (max-width: 1121px)
    margin 0 3%
    margin-right 0
  @media screen and (max-width: 769px)
    margin-left 0
  @media screen and (max-width: 490px)
    width 100%
    justify-content center
    margin-top 20px
  li
    margin-left 5px
    &:first-child
      margin-left 0
      
.new_post
  position relative
  font-family fontLight
  font-size rem(18) 
  line-height 1.5 
  padding-left 68px
  display inline-block
  @media screen and (max-width: 778px)
    height 30px
    position relative
    top -7px
  @media screen and (max-width: 375px)
    padding-left 40px
  span
    //@media screen and (max-width: 778px)
    //  display none
    +r(778)
      display none

  &:before
    content " "
    background url("../img/general/post.png")
    display block
    size(48px)
    absolute($top: 0, $left: 0)


  