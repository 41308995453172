.info_price
  display flex
  justify-content space-between
  align-items center
  text-align center
  margin-bottom 100px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 980px)
    flex-wrap wrap
  @media screen and (max-width: 736px)
    margin-bottom 30px

.br__right
  border 30px solid colorRed
  border-left 0
  padding 90px 30px
  @media screen and (max-width: 1025px)
    padding 50px 0
  @media screen and (max-width: 980px)
    padding 35px 30px
  @media screen and (max-width: 621px)
    padding-bottom 15px
.info_price--left
  width 670px
  @media screen and (max-width: 980px)
    width 100%
  .h3_
    margin-bottom 60px
    @media screen and (max-width: 980px)
      margin-bottom 35px
    br
      @media screen and (max-width: 980px)
        display none
.info_price--right
  width 410px
  margin-top -38px
  @media screen and (max-width: 980px)
    width auto
    margin 40px auto 0
    max-width 620px
  .h3_
    margin-bottom 70px
    br
      @media screen and (max-width: 980px)
        display none
    @media screen and (max-width: 980px)
      margin-bottom 40px

