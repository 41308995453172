.like_m
  position relative
  border 30px solid colorRed
  border-right 0
  padding 50px 0 10px 73px
  margin-bottom 80px
  display flex
  justify-content space-between
  &.blue
    border-color colorBgBlue
    &.rightStick:after, &.leftStick:after,
    &.rightStick:before, &.leftStick:before
      background-color colorBgBlue

  @media screen and (max-width: 840px)
    flex-wrap wrap
    padding 35px 0 10px 28px
  @media screen and (max-width: 568px)
    padding-left 20px
  .h2_page
    flex 1 1 27%
    text-align left
    @media screen and (max-width: 840px)
      flex 1 1 100%
      text-align center
  .list-like
    flex 1 1 73%
    @media screen and (max-width: 840px)
      flex 1 1 100%
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 736px)
    margin-bottom 30px
.list-like
  display flex
  flex-wrap wrap
  align-items center
  li
    flex 1 1 50%
    padding-right 20px
    margin-bottom 40px
    display flex
    align-items center
    @media screen and (max-width: 840px)
      margin-bottom 25px
    @media screen and (max-width: 568px)
      flex 1 1 100%
    img
      display inline-block
    span
      display inline-block
      margin-left 30px
      font-size rem(24)
