.model
  padding 100px 0
  border-top 2px solid #000000
  border-bottom 2px solid #000000
  margin-bottom 50px
  display flex
  align-items center
  @media screen and (max-width: 1170px)
    padding 50px 0
  @media screen and (max-width: 840px)
    align-items flex-start
  @media screen and (max-width: 736px)
    padding 30px 0
    margin-bottom 30px
  @media screen and (max-width: 600px)
    flex-wrap wrap

.moder_item
  flex 1 1 50%
  @media screen and (max-width: 600px)
    flex 1 1 100%
  &:first-child
    text-align center
    @media screen and (max-width: 600px)
      margin-bottom 30px
    a
      display inline-block
    img
      display block
      max-width 100%
      height auto
      margin auto
    @media screen and (max-width: 840px)
      padding 0 60px
  &:last-child
    display flex
    justify-content flex-end
    @media screen and (max-width: 840px)
      margin-left 60px
    @media screen and (max-width: 600px)
      margin-left 0

.model_content
  max-width 370px
  width 100%
  @media screen and (max-width: 840px)
    max-width inherit
  .h3_
    margin-bottom 40px
    @media screen and (max-width: 840px)
      margin-bottom 25px
  ul
    margin-bottom 80px
    @media screen and (max-width: 1170px)
      margin-bottom 50px
    @media screen and (max-width: 840px)
      margin-bottom 30px
    li
      font-size rem(24)
      line-height 1.2
      margin-bottom 15px
      position relative
      padding-left 30px
      font-family fontLight
      &:last-child
        margin-bottom 0
      &:before
        content " "
        absolute($top: 11px, $left: 0)
        size(10px)
        border-radius 50%
        border solid 2px #000
        display block

.mod__link
  display inline-block
  position relative