.ing_descr
  //overflow hidden
  clearfix()
  .ing_main-row
    margin-bottom 0
    +r(960)
      margin-bottom 40px
    +r(600)
      margin-bottom 0

  +r(1170)
    margin-bottom 40px
  +r(960)
    margin-bottom 20px

  .ing_main--txt
    font-size rem(18)
    font-weight 1.3
    max-width 42%
    width 100%
    float right
    font-family fontLight
    p
      margin-bottom 20px
    +r(960)
      max-width inherit
      width: 100%;
  .ing_main--cont img
    top -30px
    +r(960)
      top -10px
      display inline-block
      vertical-align middle

.ing_main.second.ing_descr .ing_main-row
  +r(600)
    margin-bottom 0
