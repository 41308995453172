.propose
  position relative
  display flex
  justify-content space-between
  //align-items center
  margin-bottom 80px
  @media screen and (max-width: 1170px)
    margin-bottom 50px
  @media screen and (max-width: 840px)
    flex-wrap wrap
  @media screen and (max-width: 736px)
    margin-bottom 30px
.propose_left
  padding 60px
  width 450px
  background-image url("../img/content/paternt_about.png")
  background-repeat no-repeat
  background-size cover
  display flex
  align-items center
  @media screen and (max-width: 840px)
    width 100%
    flex 1 1 100%
    padding 35px 60px 10px
    margin-right -20px
  @media screen and (max-width: 568px)
    padding 35px 48px 10px
.propose_right
  position relative
  border 30px solid colorRed
  border-right 0
  padding 80px 30px 80px 80px
  width 720px
  text-align center
  display flex
  align-items center
  justify-content center
  @media screen and (max-width: 1130px)
    padding 80px 30px 60px 80px
  @media screen and (max-width: 840px)
    width 100%
    flex 1 1 100%
    padding 40px 0

  .info_ic_gr
    width 100%
    justify-content space-between
    @media screen and (max-width: 1130px)
      margin 0 -15px
      justify-content center
    .info_ic_item
      margin 0 3%
      @media screen and (max-width: 1130px)
        padding 0 10px
        margin-bottom 20px